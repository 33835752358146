import store from '../store';
import { getStoreBuildTodoCount, getNodeListCount, getTaskNum } from '@/api/workflow/index';
import { computed } from 'vue';

export const getStoreBuildTodoNum = async () => {
  // const { data = 0 } = await getStoreBuildTodoCount();
  // store.commit('setStoreBuildTodoCount', data);
  // new
  getTaskNumTotal(1)
};
export const getTaskNumTotal = async (type) => {
  /**
   *  1：待办数
      2：已办数
      3：抄送数
      4：已阅数
   */
  const { data = {} } = await getTaskNum({ type });
  if (type == 1) {
    store.commit('setCount', { data: data.num, type: 'storeBuildTodoCount' });
  } else if (type == 3) {
    store.commit('setCount', { data: data.num, type: 'storeBuildCcCount' });
  }
};
export const getStoreBuildNodeMonitorErrorCount = async () => {
  //  TODO: 暂时关闭显示异常节点的功能，该接口性能差，不适合频繁调用
  const result = await getNodeListCount({ pageNo: 1, pageSize: 20, suggest: '', isError: 2 });
  store.commit('setStoreBuildNodeMonitorErrorCount', result.data);
};
export const hasTmpAuth = computed(() => {
  return store.state.menu.tmpAuthPhoneList.includes(store.state.tenant.userInfo?.phone);
});
export const hasExportDataAuth = computed(() => {
  return [
    '13631278451',
    '13631278452',
    '15901418041',
    '18910047016',
    '15110042763',
    '15128248410',
  ].includes(store.state.tenant.userInfo?.phone);
});
export const hasRefundOrderAndMoney = computed(() => {
  return ['13631278451', '13631278452', '15901442367'].includes(store.state.tenant.userInfo?.phone);
});
