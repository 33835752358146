// 明之BI v2  --- 之后可能会迁移至新系统
import BIV2 from './BI-v2/index';
import setting from './setting';
//智能巡店
import patrolTask from './patrolTask/index';
import { MzLayout } from '@/layouts';
// 工作流模块
import workflow from './workflow';
// 招商
import investment from '@/investment/router';
// 外部人员管理
import externalPersonnelManagement from './externalPersonnelManagement';
// const Layout = () => import(/* webpackChunkName: "layout" */ "@/layouts");

const NotFound = () => import(/* webpackChunkName: "404" */ '../pages/404');

// 直发模版
const straightHairPurchase = () => import('../pages/straightHair/purchase');
// 直发模版
const straightHairPrepare = () => import('../pages/straightHair/prepare');
// 直发模版
const straightHairSupplier = () => import('../pages/straightHair/supplier');
// 店长库管理
const storeLib = () => import(/* webpackChunkName: "storeLib" */ '@/pages/storeLib/list');
// 短信管控
const smsControl = () => import(/* webpackChunkName: "smsControl" */ '@/pages/smsControl/list');
const loginIndex = () => import('../pages/login.vue');
const oldDomain = () => import('../pages/oldDomain.vue');

let routers = [
  {
    path: '/login',
    name: 'login',
    component: loginIndex,
  },
  {
    path: '/',
    redirect: '/workflow/overviewLight',
  },
  {
    path: '/',
    component: MzLayout,
    children: [
      {
        path: '/straightHairPurchase',
        name: 'straightHairPurchase',
        component: straightHairPurchase,
        meta: {
          pageName: '采购看板',
        },
      },
      {
        path: '/straightHairPrepare',
        name: 'straightHairPrepare',
        component: straightHairPrepare,
        meta: {
          pageName: '筹备看板',
        },
      },
      {
        path: '/straightHairSupplier',
        name: 'straightHairSupplier',
        component: straightHairSupplier,
        meta: {
          pageName: '供应商看板',
        },
      },
      {
        path: '/storeLib/list',
        name: 'storeLib',
        component: storeLib,
        meta: {
          keepAlive: true,
          pageName: '合伙制店长管理',
        },
      },
      {
        path: '/smsControl/list',
        name: 'smsControl',
        component: smsControl,
        meta: {
          pageName: '短信管控',
        },
      },
    ],
  },
  {
    path: '/oldDomain',
    name: 'oldDomain',
    component: oldDomain,
  },
];

const other = [BIV2, setting, workflow, investment, patrolTask, externalPersonnelManagement];
// 拼接其它模块路由
// let otherList = [];
// otherList = otherList.concat(storeIntelRouters);
// otherList = otherList.concat(shop, cms, customerOperation, setting, posOrder, );
routers[2].children = routers[2].children.concat(...other);
export default [
  ...routers,
  {
    path: '*',
    name: '404',
    component: NotFound,
  },
];
