const state = {
  newMenus: [],
  menus: {},
  modules: [],
  permissions: [],
  sourceMenus: [],
  permissionsRouters: [],
  currentMenu: {
    selectedKeys: [], //当前选择的菜单id
    openKeys: [], //需要展开的菜单
    moduleId: null, //模块id
  },
  isCollapse: false, //菜单是否折叠

  storeBuildTodoCount: 0, //开店项目的待办数量
  storeBuildCcCount: 0, //开店项目的抄送数量
  storeBuildNodeMonitorErrorCount: 0, //节点监控异常数量

  // 面包屑功能
  tmpAuthPhoneList: [], //允许编辑流程模版的用户
  tagList: localStorage.getItem('tagList') ? JSON.parse(localStorage.getItem('tagList')) : [],

  menuData: {
    menuTab: localStorage.getItem('menuTabB') ? JSON.parse(localStorage.getItem('menuTabB')) : [],
    menuIdB: localStorage.getItem('menuIdB') ? localStorage.getItem('menuIdB') : '',
  },
};
import router from '@/router';
// 记录父级导航栏 - 分享时查找父级导航栏 - 多层父级遵循从 父 => 父的父
// key写的条件是精准匹配
let parentMap = {
  '/workflow/buildStore/detail?type=todo': ['workflow/todoList/home'],
  '/workflow/buildStore/detail?isMonitor=false': ['workflow/buildStore/list'],
  '/workflow/buildStore/detail?isMonitor=true': ['workflow/projectMonitor/list'],
  '/workflow/todoList/detail': ['workflow/todoList/home'],
  '/workflow/sentList/detail': ['workflow/sentList/home'],
  '/workflow/readList/detail': ['workflow/readList/home'],
  '/workflow/doneList/detail?pageType=xmlb': [
    'workflow/buildStore/detail',
    'workflow/buildStore/list',
  ], // 项目流程
  '/workflow/doneList/detail?pageType=xmgk': [
    'workflow/buildStore/detail',
    'workflow/projectMonitor/list',
  ], // 项目管控
  '/workflow/doneList/detail': ['workflow/doneList/home'], // 已办
  '/workflow/template/draw': ['workflow/template/list'],
  '/workflow/template/drawDetail': ['workflow/template/list'],
  '/workflow/dataMng/configDetail': ['workflow/dataMng/list'],
  '/workflow/dataMng/detail': ['workflow/dataMng/list'],
  '/workflow/projectMonitor/editForm': ['workflow/projectMonitor/list'],
  '/workflow/doneList/print': ['workflow/doneList/detail', 'workflow/doneList/home'],
};
function getData(data) {
  if (typeof data !== 'object') return new Error('参数错误');
  const tagProps = ['path', 'name', 'meta', 'query'];
  return tagProps.reduce((obj, key) => {
    obj[key] = data[key];
    return obj;
  }, {});
}
function filterData(state, data) {
  // 清洗数据
  let targetData = getData(data);
  let parentList = getParentList(targetData);
  // 判断是否多级路由
  if (!parentList) {
    state.tagList = [targetData];
  } else {
    // 是否存在之前浏览记录
    let oldIndex = state.tagList.findIndex((item) => {
      return item.path == data.path;
    });

    if (oldIndex === -1) {
      let startPath = parentList[0] || '',
        lastItem = state.tagList[state.tagList.length - 1] || {};

      // 判断是否能push的子级
      if (state.tagList.length && lastItem && lastItem.path.indexOf(startPath) > -1) {
        state.tagList.push(targetData);
      } else {
        // 新打开的页面 - 拼接新多级页面
        // let list = parentList.reverse().reduce((arr, path) => {
        //   // 使用match方法获取route信息
        //   const routerItem = router.match(path);
        //   return arr.concat([getData(routerItem)]);
        // }, []);
        // list.push(targetData);
        state.tagList = [targetData];
      }
    } else if (oldIndex !== state.tagList - 1) {
      // 返回点击情况
      state.tagList = state.tagList.slice(0, oldIndex + 1);
    }
  }
  localStorage.setItem('tagList', JSON.stringify(state.tagList));
}
function getParentList(routerItem) {
  let itemPath = Object.keys(parentMap).find((path) => {
    const item = router.match(path);
    let queryKeys = Object.keys(item.query);
    if (
      routerItem.path === item.path &&
      (!queryKeys.length ||
        queryKeys.some((key) => {
          return item.query[key] === routerItem.query[key];
        }))
    ) {
      return path;
    }
  });
  return parentMap[itemPath] ? parentMap[itemPath].slice(0, parentMap[itemPath].length) : null;
}
const mutations = {
  //设置面包屑列表
  setTagList: (state, data) => {
    filterData(state, data);
  },

  //设置操作权限
  setPermissions: (state, data) => {
    state.permissions = data;
  },
  //设置菜单源数据
  setSourceMenus: (state, data) => {
    state.sourceMenus = data;
  },

  //设置路由权限
  setPermissionsRouters: (state, data) => {
    state.permissionsRouters = data;
  },
  //设置所有新菜单列表
  setNewMenus: (state, data) => {
    state.newMenus = data;
  },

  //设置所有菜单列表
  setMenus: (state, data) => {
    state.menus = data;
  },
  //设置允许编辑流程模版的用户
  setTmpAuthPhoneList: (state, data) => {
    state.tmpAuthPhoneList = data;
  },

  setModules: (state, data) => {
    state.modules = data;
  },

  setCurrentMenu: (state, data) => {
    state.currentMenu = data;
  },
  setIsCollapse: (state, data) => {
    state.isCollapse = data;
  },
  setStoreBuildTodoCount: (state, data) => {
    state.storeBuildTodoCount = data;
  },
  setStoreBuildNodeMonitorErrorCount: (state, data) => {
    state.storeBuildNodeMonitorErrorCount = data;
  },
  setCount: (state, { data, type }) => {
    if (!type) return;
    state[type] = data;
  },
};
const actions = {};
export default {
  state,
  mutations,
  actions,
};
