// modalPlugin.js
import Vue from 'vue';
function removeInstance(instance) {
  if (instance.$el && instance.$el.parentNode) {
    instance.$el.parentNode.removeChild(instance.$el);
  }
  instance.$destroy();
}
function showModal(component, options) {
  const ModalConstructor = Vue.extend(component);
  const instance = new ModalConstructor({
    propsData: {
      modalVisible: true,
      ...options,
    },
  });

  instance.$mount();
  document.body.appendChild(instance.$el);
  instance.$on('confirm', (result) => {
    if (options.onConfirm) {
      options.onConfirm(result);
    }
    removeInstance(instance);
  });

  instance.$on('cancel', () => {
    if (options.onCancel) {
      options.onCancel();
    }
    removeInstance(instance);
  });
  return instance;
}

export default {
  install(Vue) {
    Vue.prototype.$showCustomModal = showModal;
  },
};
